"use client";
import { useState } from "react";
import { Box, Collapse, Container, Stack, Typography } from "@mui/material";
import {
  TerminalRounded as DocsIcon,
  SearchRounded as SearchIcon,
  ExpandLess as HideIcon,
  ExpandMore as ShowIcon
} from "@mui/icons-material";
import ButtonFilled from "../../../component/Button/Filled";
import ButtonText from "../../../component/Button/Text";
import ApiIcon from "@mui-symbols-material/w400/DeployedCodeRounded";

export default function Links({ results }) {
  const [show, setShow] = useState(false);

  return (
    <Container maxWidth="medium">
      <Box
        sx={{
          bgcolor: "var(--dark-surface-container-lowest)",
          borderRadius: "var(--shape-md-round)"
        }}
      >
        <Box
          bgcolor="var(--dark-surface-container-low)"
          borderRadius="var(--shape-md-top)"
          p={2}
          pb={1}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              color="var(--dark-surface-on-color)"
              variant="headlineSm"
              component="p"
            >
              Get started
            </Typography>
            <ButtonFilled
              size="small"
              onClick={() => setShow(show => !show)}
              IconEnd={show ? HideIcon : ShowIcon}
            />
          </Stack>
          <Stack
            direction="row"
            pt={2}
            alignItems="center"
            sx={{
              "& a": {
                color: "var(--dark-secondary-color)",
                "&:hover": {
                  color: "var(--dark-secondary-on-container)",
                  bgcolor: "var(--dark-secondary-container)"
                },
                "&:hover svg": {
                  fill: "var(--dark-secondary-on-container)"
                }
              },
              "& svg": {
                fill: "var(--dark-secondary-on-container)"
              }
            }}
          >
            <ButtonText
              size="small"
              label="API dashboard"
              IconStart={ApiIcon}
              href="/api"
            />
            <ButtonText
              size="small"
              label="Search all models"
              IconStart={SearchIcon}
              href="/search"
            />
            <ButtonText
              size="small"
              label="Docs"
              IconStart={DocsIcon}
              href="https://docs.bytez.com"
              target="_blank"
            />
          </Stack>
        </Box>
        <Collapse
          in={show}
          collapsedSize={150}
          sx={show ? undefined : theme => theme.fadeEdge("bottom", 80)}
        >
          <Stack spacing={2} p={2}>
            {results.map(([category, rows]) => (
              <div key={category}>
                <Typography
                  variant="titleSm"
                  color="var(--dark-surface-on-color)"
                >
                  {category}
                </Typography>
                <Stack spacing={1} flexWrap="wrap" direction="row" pt={1}>
                  {rows.map(([task, count, icon]) => (
                    <Stack
                      key={task}
                      spacing={1}
                      direction="row"
                      alignItems="center"
                      height={32}
                      px={1}
                      borderRadius="var(--shape-round)"
                      bgcolor="var(--dark-surface-container-highest)"
                      component="a"
                      target="_blank"
                      href={
                        task === "chat"
                          ? "https://docs.bytez.com/model-api/docs/chat/text"
                          : `https://docs.bytez.com/model-api/docs/all-tasks/${task}`
                      }
                      sx={{
                        transition: "all 200ms var(--motion-easing-emphasized)",
                        textDecoration: "none",
                        "&:hover": {
                          bgcolor: "var(--dark-surface-container-color)"
                        }
                      }}
                    >
                      <Box
                        sx={{
                          maskImage: `url(${icon})`,
                          maskRepeat: "no-repeat",
                          WebkitMaskRepeat: "no-repeat",
                          backgroundColor: "var(--colors-success-70)",
                          width: 16,
                          height: 16
                        }}
                      />
                      <Typography
                        variant="labelSm"
                        textTransform="capitalize"
                        color="var(--dark-surface-on-color)"
                      >
                        {task.replaceAll("-", " ")}
                      </Typography>
                      <Typography
                        px={0.75}
                        variant="labelSm"
                        textTransform="capitalize"
                        borderRadius="var(--shape-round)"
                        color="var(--dark-secondary-on-container)"
                        bgcolor="var(--dark-secondary-container)"
                      >
                        {count}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </div>
            ))}
          </Stack>
        </Collapse>
      </Box>
    </Container>
  );
}
